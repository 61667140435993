import TagManager from "react-gtm-module";

export const pushEventToDataLayer = (payload) => {
  console.log("pushEventToDataLayer", payload);
  if (process.env.REACT_APP_LOCAL == "1") return;
  const tagManagerArgs = { dataLayer: payload };
  console.debug(tagManagerArgs);
  TagManager.dataLayer(tagManagerArgs);
  // window.dataLayer.push({
  //   ...payload,
  // });
};
