function BannerEspecial({ mensagem }) {
  return (
    <div className="rounded-t-xl relative isolate flex flex-col flex-shrink-0 items-center gap-x-6 overflow-hidden bg-gray-50 py-2">
      <div className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-40"></div>
      </div>
      <div className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-40"></div>
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-xs leading-snug text-center  text-gray-800">
          <strong className=" font-semibold">Oferta Especial</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx="1" cy="1" r="1" />
          </svg>
          <strong className="font-normal ">{mensagem}</strong>
        </p>
      </div>
    </div>
  );
}

export default BannerEspecial;
