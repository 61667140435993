export const faqAquisicao = {
  todos: [
    {
      pergunta: "O que é portabilidade?",
      resposta: {
        label:
          "A portabilidade numérica é um processo que permite aos usuários de telefonia trocar de operadora e manter o seu número. Para que a portabilidade seja possível, é necessário que:",
        list: {
          items: [
            "Ocorra dentro de um mesmo código de área (DDD), no caso de telefonia móvel (SMP);",
            "Ocorra dentro de uma mesma área local, no caso de telefonia fixa (STFC).",
          ],
        },
      },
      postLabel: "A portabilidade somente é permitida para o mesmo tipo de serviço: de móvel para móvel e de fixo para fixo.",
    },
    {
      pergunta: "Como eu faço a portabilidade?",
      resposta: {
        label: "Primeiro escolha um plano de sua preferência, após isso realize a contratação. ",
        list: {
          items: [
            "Siga as orientações de confirmação via SMS que enviaremos durante o processo.",
            "Aguarde a entrega do TIM chip no local escolhido para recebê-lo.",
            "Com o chip em mãos, desligue o aparelho e insira seu novo TIM chip. ",
          ],
        },
      },
    },
    {
      pergunta: "Como eu realizo a confirmação da portabilidade?",
      resposta: {
        list: {
          items: [
            "Envie a palavra PORTABILIDADE via SMS para 7678.",
            "Você receberá uma resposta pedindo para informar qual a operadora que você escolheu. Responda o SMS escrevendo TIM.",
            "Atenção! Responda o segundo SMS em até 30min.",
          ],
        },
      },
    },
    {
      pergunta: "E se não enviei o SMS ou não respondi com a palavra TIM?",
      resposta: {
        list: {
          items: [
            "Não se preocupe, um SMS chegará até você perguntando se solicitou a portabilidade.",
            "Responda com a palavra: SIM ao SMS que receberá confirmando que você solicitou a portabilidade do seu número para outra operadora.",
          ],
        },
      },
    },
    {
      pergunta: "Quanto tempo leva para trazer o meu número para a TIM?",
      resposta:
        "Realize seu pedido e assim que recebemos a confirmação de entrega do seu chip, iniciamos o pedido de portabilidade, que pode durar até 3 dias úteis. Depois, é só ativar o seu TIM Chip e aproveitar os benefícios do seu plano TIM!",
    },
    {
      pergunta: "Quais são as regras para solicitação de portabilidade?",
      resposta: {
        label: "Para solicitar a portabilidade, você precisa:",
        list: {
          items: [
            "Ser o titular do número que você quer trazer para a TIM;",
            "Ter o seu número ativo na sua operadora atual;",
            "Estar dentro de um mesmo DDD (no caso de telefonia móvel) ou na mesma área local (no caso de telefonia fixa).",
            "Será necessário autenticar a solicitação de portabilidade por SMS, no caso dos planos de telefonia móvel para pessoas físicas.",
          ],
        },
      },
    },
    {
      pergunta: "Serei cobrado pela solicitação de portabilidade?",
      resposta:
        "O regulamento da Anatel autoriza a operadora que receberá o Cliente a cobrar uma taxa pela portabilidade, mas os clientes que vierem para a TIM estarão promocionalmente isentos desta cobrança.",
    },
    {
      pergunta: "Como saber se a portabilidade foi feita?",
      resposta:
        "Você vai receber um SMS informando que o seu número já é TIM. Além disso, ao solicitar a portabilidade, vamos enviar um Bilhete de Portabilidade, referente a sua solicitação, com a data e hora agendada para a mudança. Você precisa estar com o Chip no aparelho na data e hora marcada. (encaminharemos uma mensagem 24h antes do dia marcado).\n" +
        "Caso não se recorde a data e horário em que sua portabilidade foi agendada,",
    },

    {
      pergunta: "Existe limite para solicitar a portabilidade?",
      resposta:
        "Não existe limite. O Cliente pode mudar de operadora quantas vezes desejar. No entanto, caso você tenha algum contrato de fidelização, ele será cobrado.",
    },
    {
      pergunta: "Preciso esperar um tempo mínimo na minha operadora para solicitar a portabilidade?",
      resposta:
        "Não é necessário ter um tempo mínimo para trazer o seu número para a TIM. Você pode solicitar a qualquer tempo a portabilidade de seu número de outra operadora para a TIM.",
    },
    {
      pergunta: "Posso desistir da portabilidade?",
      resposta: "Sim, mas você precisa solicitar o cancelamento até dois dias úteis após a solicitação de portabilidade para que ela seja cancelada.",
    },
    {
      pergunta: "Ainda tenho dúvidas sobre a portabilidade TIM, para onde devo ligar?",
      resposta: "Caso ainda tenha dúvidas sobre a portabilidade para a TIM, entre em contato com a nossa Central de Atendimento (1056).",
    },
    {
      pergunta: "Por que eu preciso confirmar a portabilidade via SMS?",
      resposta: {
        label:
          "A confirmação da portabilidade via SMS é um requisito de segurança estabelecido pela ANATEL para evitar que sua linha seja portada indevidamente. Existem duas formas de validar seu pedido de portabilidade via SMS:",
        list: {
          items: ["SMS de dupla validação", "SMS prévio de dupla validação"],
        },
      },
    },
    {
      pergunta: "SMS de dupla validação",
      label:
        "No SMS de dupla validação, você receberá duas mensagens do número 7678, no seu celular: primeiro você receberá uma mensagem informativa (conforme modelo de aparelho) sobre a solicitação de portabilidade, e, logo em seguida, a segunda mensagem solicitando a confirmação do seu pedido de portabilidade.\n" +
        "\nNeste método de validação, basta responder ao segundo SMS, de confirmação, com “SIM”, caso reconheça a solicitação ou “NÃO”, caso desconheça. O prazo de resposta deste SMS é de 24h, mas o ideal é que você responda o quanto antes.",
      resposta: [
        {
          label: "Confira abaixo o macro fluxo deste método de validação, considerando um pedido de portabilidade realizado pelo cliente:",
          list: {
            numerada: true,
            items: [
              "Cliente solicita a Portabilidade em um canal TIM.",
              "Cliente recebe o primeiro SMS informativo do número 7678, exemplo: “Olá, recebemos a solicitação da portabilidade do seu numero 11999999999 pela TIM. Para sua segurança, você recebera um SMS para confirmar o pedido.”",
              "Cliente recebe o segundo SMS, de confirmação, do número 7678, exemplo: “Olá, você solicitou a portabilidade do 11999999999 para TIM? Para sua segurança, responda em até 24 horas: SIM para confirmar ou NAO para cancelar.”",
              "Cliente responde SIM ao SMS 7678*.",
              "Cliente recebe um SMS do número 7678 confirmando a solicitação, exemplo: “Olá, recebemos sua confirmação para seguir com o pedido de portabilidade do 11999999999 para a TIM”.",
              "Ocorre a janela de portabilidade mínima de 72 horas úteis.",
              "Portabilidade concluída.",
            ],
          },
        },
      ],
      postLabel: "Caso o Cliente responda “Não” ao SMS de confirmação (3 acima), o pedido de portabilidade é cancelado.",
    },

    {
      pergunta: "SMS prévio de dupla validação",
      label:
        "Outra forma de validar um pedido de portabilidade é realizando a confirmação prévia quando um consultor TIM solicita que o cliente envie ativamente a palavra PORTABILIDADE via SMS para o número 7678, em paralelo à abertura do pedido de portabilidade pelo consultor.\n" +
        "\nImportante ressaltar que o envio prévio e ativo do SMS somente deve ocorrer quando um consultor TIM solicitar que seja efetuado.",
      resposta: [
        {
          label: "Confira abaixo o macro fluxo deste método de validação, considerando um pedido de portabilidade realizado pelo cliente:",
          list: {
            numerada: true,
            items: [
              "Cliente solicita a Portabilidade em um canal TIM.",
              "O Consultor TIM solicita que o cliente envie a palavra PORTABILIDADE via SMS para o número 7678.",
              "Cliente recebe um SMS do número 7678, exemplo: “Olá, recebemos a sua solicitação de portabilidade do 11999999999. Necessário resposta com o nome da operadora que deseja portar seu numero, em ate 30 minutos.”",
              "Cliente responde TIM ao SMS 7678 dentro do prazo mencionado.",
              "Cliente recebe o SMS do número 7678 confirmando a solicitação, exemplo: “Olá, solicitação da portabilidade do 11999999999 para TIM foi recebida com sucesso (Validade: 00/00/00 00:00). Caso queira desistir, envie CANCELAR.",
              "Ocorre a janela de portabilidade mínima de 72 horas úteis.",
              "Portabilidade concluída.",
            ],
          },
        },
      ],
      postLabel:
        "Caso o Cliente não envie ou não responda ao SMS de confirmação (3 acima) dentro do prazo, o pedido de portabilidade prévio não é realizado.",
    },

    {
      pergunta: "O que acontece se eu não responder o SMS de confirmação da portabilidade no prazo de 24 horas?",
      resposta:
        "Caso não responda o SMS dentro do prazo de 24 horas, a TIM enviará um novo SMS e, novamente, você terá até 24 horas para responder.\n" +
        "\nO SMS enviado é assim: “Olá, você solicitou a portabilidade do 11999999999 para TIM? Para sua segurança, responda em ate 24 horas: SIM para confirmar ou NAO para cancelar.”\n" +
        "\nNa ausência de resposta, sua solicitação de portabilidade será cancelada em 30 dias da data da solicitação, e, caso tenha solicitado uma portabilidade com número temporário, o mesmo permanecerá ativo e irá gerar cobranças.",
    },

    {
      pergunta: "O que acontece se eu responder NÃO ao SMS de confirmação da portabilidade?",
      resposta:
        "Caso responda com NÃO, a solicitação de portabilidade será cancelada automaticamente, e, caso tenha solicitado uma portabilidade com número temporário, o mesmo permanecerá ativo e irá gerar cobranças. Se quiser efetuar novamente o pedido, você deve solicitar uma nova portabilidade para a TIM. Para dúvidas em caso de cancelamento da portabilidade e permanência do número temporário, ligue *144 do seu TIM ou ligue para 1026 de qualquer número de outra operadora.",
    },

    {
      pergunta: "E se eu não receber o SMS para confirmação de portabilidade?",
      resposta:
        "Se você não recebeu o SMS para autenticação do pedido de portabilidade, confirme se o seu celular está habilitado para o recebimento de SMS. Se esse não for o problema, entre em contato com a TIM para que sejam investigadas outras possíveis falhas, por favor.",
    },
    {
      pergunta: "O SMS de confirmação da portabilidade é cobrado?",
      resposta: "Não, tanto o envio quanto o recebimento dos SMS do pedido de portabilidade são gratuitos e não descontam do seu saldo.",
    },
    {
      pergunta: "Qual a diferença da portabilidade com número temporário e sem número temporário?",
      resposta:
        "O número temporário TIM permite a utilização da oferta contratada e de todos os seus benefícios de forma imediata até a conclusão da portabilidade, é opcional e pode ser adquirido no processo de aquisição do seu plano TIM. Em uma portabilidade sem número temporário, o cliente não pode adquirir aparelhos e acessórios com desconto de fidelização e apenas poderá usufruir de todos os benefícios TIM após a conclusão da portabilidade.",
    },
    {
      pergunta: "Como ocorre a cobrança em cenário de portabilidade sem número temporário?",
      resposta:
        "Em portabilidade sem número temporário, o cliente estará elegível apenas para a utilização dos serviços e a emissão da cobrança após a conclusão com sucesso da portabilidade. Nos planos Express, a cobrança no cartão de crédito será enviada após a conclusão da portabilidade. No caso de ausência de saldo, o plano Express será substituído pelo plano pré-pago, e a portabilidade será concluída normalmente. Nos planos fatura, a cobrança será enviada de acordo com o ciclo de faturamento do cliente.",
    },
    {
      pergunta: "O que acontece com o número temporário em caso de cancelamento da portabilidade TIM?",
      resposta:
        "Em caso de cancelamento da portabilidade, o número temporário continua apto a utilização e irá gerar cobranças. Para dúvidas em caso de cancelamento da portabilidade e permanência do número temporário ligue para *144 do seu TIM ou ligue para 1056 de qualquer número de outra operadora.",
    },
    {
      pergunta: "É possível adquirir aparelho e/ou acessório fidelizado ao solicitar a portabilidade?",
      resposta:
        "Sim, para portabilidade com solicitação de número temporário o cliente poderá adquirir aparelhos e/ou acessórios com desconto de fidelização no momento da solicitação da aquisição de plano TIM com portabilidade. Caso a portabilidade seja cancelada, a fidelização permanecerá atrelada ao número temporário. Em caso de portabilidade sem número temporário, após a conclusão do processo, o cliente pode se dirigir a uma loja TIM e verificar as ofertas disponíveis de aparelhos e/ou acessórios.",
    },
    {
      pergunta: "O que fazer se ocorrer falha na tentativa de resposta do SMS de confirmação da portabilidade?",
      label:
        "Para o cenário de “falha de envio” de um SMS, geralmente sinalizado pelo símbolo , a solução pode estar na configuração de mensagem do seu smartphone. Veja o passo a passo de acordo com o modelo do sistema operacional do seu aparelho:",
      resposta: [
        {
          label: "Samsung (Android)",
          list: {
            items: [
              "Acessar a opção “Configurações”.",
              "Clicar em “Aplicativos”.",
              "Na parte superior direita da tela, clicar nos “3 pontos” e selecionar “Acesso especial”.",
              "Clicar na opção “Usar serviços mens. de texto premium”.",
              "Clicar sobre a opção “Mensagens”.",
              "Selecionar a opção “Permitir sempre”. E pronto!",
            ],
          },
        },
        {
          label: "Motorola (Android)",
          list: {
            items: [
              "Acessar as “Configurações” e depois clicar em “Apps”.",
              "Clicar em “Acesso especial a apps”.",
              "Clicar na opção “Acesso a SMS premium”.",
              "Clicar na opção “Mensagens” em “Acesso especial”. E pronto!",
            ],
          },
        },
        {
          label: "Iphone (IOS)",
          list: {
            items: [
              "Clique  no que aparece ao lado do SMS com falha de envio.",
              "Abaixo aparecerá um menu na tela e clique em “tentar novamente”.",
              "Se ainda persistir o erro, clique em “enviar como SMS”. E pronto!",
            ],
          },
        },
      ],
      postLabel:
        "Caso o problema persista ligue para *144 do seu TIM ou ligue para 1056 de qualquer número de outra operadora.\n" +
        "\nLembrando que, na ausência de resposta do SMS de confirmação da portabilidade, sua solicitação será cancelada em 30 dias da data da solicitação. Além disso, caso tenha solicitado uma portabilidade com número temporário, o mesmo permanecerá ativo e irá gerar cobranças.",
    },
  ],
};
